import log from 'loglevel';

import svgAppIphone from '../assets/images/iphone.svg';
import svgAppAndroid from '../assets/images/android.svg';

class AppLinker {
    el?: HTMLElement;

    static get isApp() {
        return /BLNAPP/.test(navigator.userAgent);
    }

    apply() {
        if (AppLinker.isApp) return;

        this.el = document.getElementById('app') as HTMLElement;
        log.info(`AppLinker: got el ${this.el}`);
        if (!this.el) return;

        const linkAppIphone = this.buildAppIphone();
        const linkAppAndroid = this.buildAppAndroid();

        if (linkAppIphone + linkAppAndroid) {
            this.el.innerHTML = `<ul class="list-inline text-center mt-3">${linkAppIphone}${linkAppAndroid}</ul>`;
        }
    }

    buildAppIphone() {
        if (!this.el) return '';

        const url = this.el.dataset.iphone;
        log.info(`AppLinker: iphone=${url}`);
        if (!url) return '';

        return `<li class="list-inline-item">
      <a href="${url}" id="appiphone" target="_blank"
        aria-label="Download on the App Store"><img
         src="${svgAppIphone}" width="120" height="40"
         alt="Download on the App Store"/></a></li>`;
    }

    buildAppAndroid() {
        if (!this.el) return '';

        const url = this.el.dataset.android;
        log.info(`AppLinker: android=${url}`);
        if (!url) return '';

        return `<li class="list-inline-item">
      <a href="${url}" id="appandroid" target="_blank"
        aria-label="Get it on Google Play"><img
          src="${svgAppAndroid}" width="155" height="60"
          alt="Get it on Google Play"/></a></li>`;
    }
}

export default AppLinker;
