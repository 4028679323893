import log from 'loglevel';

import svgFacebook from '../assets/images/facebook.svg';
import svgTwitter from '../assets/images/twitter.svg';
import svgApple from '../assets/images/apple.svg';
import svgSpotify from '../assets/images/spotify.svg';
import svgYoutube from '../assets/images/youtube.svg';

class IdentityLinker {
    el?: HTMLElement;
    name?: string;

    apply() {
        this.el = document.getElementById('identity') as HTMLElement;
        log.info(`IdentityLinker: got el ${this.el}`);
        if (!this.el) return;

        this.name = this.el.dataset.name;
        log.info(`IdentityLinker: name ${this.name}`);
        if (!this.name) return;

        const linkFacebook = this.buildFacebook();
        const linkTwitter = this.buildTwitter();
        const linkApple = this.buildApple();
        const linkSpotify = this.buildSpotify();
        const linkYoutube = this.buildYoutube();

        if (linkFacebook + linkTwitter + linkApple + linkSpotify + linkYoutube) {
            this.el.innerHTML = `<ul class="list-inline text-center mt-3">${linkFacebook}${linkTwitter}${linkApple}${linkSpotify}${linkYoutube}</ul>`;
        }
    }

    buildFacebook() {
        if (!this.el) return '';

        const url = this.el.dataset.facebook;
        log.info(`IdentityLinker: facebook=${url}`);
        if (!url) return '';

        return `<li class="list-inline-item">
      <a href="${url}" target="_blank"
        aria-label="Visit ${this.name} on Facebook"><img
          src="${svgFacebook}" width="40" height="40"
          alt="Visit ${this.name} on Facebook"/></a></li>`;
    }

    buildTwitter() {
        if (!this.el) return '';

        const url = this.el.dataset.twitter;
        log.info(`IdentityLinker: twitter=${url}`);
        if (!url) return '';

        return `<li class="list-inline-item">
      <a href="${url}" target="_blank"
        aria-label="Visit ${this.name} on Twitter"><img
          src="${svgTwitter}" width="40" height="40"
          alt="Visit ${this.name} on Twitter"/></a></li>`;
    }

    buildApple() {
        if (!this.el) return '';

        const url = this.el.dataset.apple;
        log.info(`IdentityLinker: apple=${url}`);
        if (!url) return '';

        return `<li class="list-inline-item">
      <a href="${url}" target="_blank"
        aria-label="Listen on Apple Music"><img
          src="${svgApple}" width="40" height="40"
          alt="Listen on Apple Music"/></a></li>`;
    }

    buildSpotify() {
        if (!this.el) return '';

        const url = this.el.dataset.spotify;
        log.info(`IdentityLinker: spotify=${url}`);
        if (!url) return '';

        return `<li class="list-inline-item">
      <a href="${url}" target="_blank"
        aria-label="Listen on Spotify"><img
          src="${svgSpotify}" width="40" height="40"
          alt="Listen on Spotify"/></a></li>`;
    }

    buildYoutube() {
        if (!this.el) return '';

        const url = this.el.dataset.youtube;
        log.info(`IdentityLinker: youtube=${url}`);
        if (!url) return '';

        return `<li class="list-inline-item">
      <a href="${url}" target="_blank"
        aria-label="Watch on YouTube"><img
          src="${svgYoutube}" width="40" height="40"
          alt="Watch on YouTube"/></a></li>`;
    }
}

export default IdentityLinker;
