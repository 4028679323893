import log from 'loglevel';

import svgApple from '../assets/images/apple.svg';
import svgSpotify from '../assets/images/spotify.svg';
import svgYoutube from '../assets/images/youtube.svg';
import svgDeezer from '../assets/images/deezer.svg';
import svgTidal from '../assets/images/tidal.svg';

class ReleaseLinker {
  el?: HTMLElement;

  artist?: string;
  title?: string;

  apply() {
    this.el = document.getElementById('release') as HTMLElement;
    log.info(`ReleaseLinker: got el ${this.el}`);
    if (!this.el) return;

    this.artist = this.el.dataset.artist;
    this.title = this.el.dataset.title;
    log.info(`ReleaseLinker: artist ${this.artist}, title ${this.title}`);
    if (!this.artist || !this.title) return;

    const linkApple = this.buildApple();
    const linkSpotify = this.buildSpotify();
    const linkYoutube = this.buildYoutube();
    const linkDeezer = this.buildDeezer();
    const linkTidal = this.buildTidal();

    if (linkApple + linkSpotify + linkYoutube + linkDeezer + linkTidal) {
      this.el.innerHTML = `<ul class="list-inline text-center mt-3">${
        linkApple}${linkSpotify}${linkYoutube}${linkDeezer}${linkTidal
      }</ul>`;
    }
  }

  buildApple() {
    if (!this.el) return '';

    const url = this.el.dataset.apple;
    log.info(`ReleaseLinker: apple=${url}`);
    if (!url) return '';

    const label = `Listen to ${this.title} by ${this.artist} on Apple Music`;
    return `<li class="list-inline-item">
      <a href="${url}" target="_blank" aria-label="${label}"><img
        src="${svgApple}" width="40" height="40" alt="${label}"/></a></li>`;
  }

  buildSpotify() {
    if (!this.el) return '';

    const url = this.el.dataset.spotify;
    log.info(`ReleaseLinker: spotify=${url}`);
    if (!url) return '';

    const label = `Listen to ${this.title} by ${this.artist} on Spotify`;
    return `<li class="list-inline-item">
      <a href="${url}" target="_blank" aria-label="${label}"><img
        src="${svgSpotify}" width="40" height="40" alt="${label}"/></a></li>`;
  }

  buildYoutube() {
    if (!this.el) return '';

    const url = this.el.dataset.youtube;
    log.info(`ReleaseLinker: youtube=${url}`);
    if (!url) return '';

    const label = `Watch ${this.title} by ${this.artist} on YouTube`;
    return `<li class="list-inline-item">
      <a href="${url}" target="_blank" aria-label="${label}"><img
        src="${svgYoutube}" width="40" height="40" alt="${label}"/></a></li>`;
  }

  buildDeezer() {
    if (!this.el) return '';

    const url = this.el.dataset.deezer;
    log.info(`ReleaseLinker: deezer=${url}`);
    if (!url) return '';

    const label = `Listen to ${this.title} by ${this.artist} on Deezer`;
    return `<li class="list-inline-item">
      <a href="${url}" target="_blank" aria-label="${label}"><img
        src="${svgDeezer}" width="40" height="40" alt="${label}"/></a></li>`;
  }

  buildTidal() {
    if (!this.el) return '';

    const url = this.el.dataset.tidal;
    log.info(`ReleaseLinker: tidal=${url}`);
    if (!url) return '';

    const label = `Listen to ${this.title} by ${this.artist} on Tidal`;
    return `<li class="list-inline-item">
      <a href="${url}" target="_blank" aria-label="${label}"><img
        src="${svgTidal}" width="40" height="40" alt="${label}"/></a></li>`;
  }
}

export default ReleaseLinker;
