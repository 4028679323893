import AppLinker from './app_linker';
import IdentityLinker from './identity_linker';
import ReleaseLinker from './release_linker';

import ahoy from 'ahoy.js';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/dropdown';

import React from 'react';
import {Root, createRoot} from 'react-dom/client';
import MusicPlayer from 'bln_player/dist/music_player';

import '@hotwired/turbo-rails';

import Rails from '@rails/ujs';

Rails.start();

const appLinker = new AppLinker();
const idLinker = new IdentityLinker();
const rLinker = new ReleaseLinker();

let root: Root;
let player: MusicPlayer;
const setPlayer = (instance: MusicPlayer) => {
    player = instance;
}

document.addEventListener('turbo:load', () => {
    ahoy.trackView();

    appLinker.apply();
    idLinker.apply();
    rLinker.apply();

    if (player) player.pageAttach();

    if (!root) {
        const foot = document.getElementById('foot') as HTMLElement;
        root = createRoot(foot);
        root.render(<MusicPlayer onCreate={setPlayer} sourceUrl="/player.json" />);
    }
});
